@import "../../../styles/base/abstracts";

.hbs-card-topper__container {
  padding-top: $grid-padding-mobile;

  @include mq($bp-tablet) {
    padding-top: $grid-padding;
  }
}

.hbs-card-topper {
  @include grid-parent;
  @include site-max-width;
  position: relative;
}

.hbs-card-topper__content,
.hbs-card-topper__art {
  @include grid-child;
}

// Content
.hbs-card-topper__content {
  order: 1;

  @include mq($bp-desktop) {
    @include absolute(x x 0 0);
    @include grid-parent;
    z-index: $z-index-1;
  }
}

.hbs-card-topper__inner {
  @include padding($spacing-md $spacing-md x);

  @include mq($bp-desktop) {
    @include grid-child;
    width: $col-6;
    max-width: 500px;

    &[data-theme] {
      background-color: transparent;
    }
  }
}

.hbs-card-topper__wrapper {
  @include padding($spacing-md);
  background-color: $c-bg;
  color: $c-text;

  @include mq($bp-desktop) {
    @include padding($spacing-xl $grid-padding * 2 x);
    display: flex;
    flex-direction: column;
    margin-left: -$grid-padding * 2;
    min-height: $card-min-height;
  }
}

.hbs-card-topper__heading {
  @include h2($visually-align: true);
  margin-bottom: $spacing-md;

  @include mq($bp-desktop) {
    @include h3;
    margin-bottom: $spacing-sm;
  }
}

.hbs-card-topper__heading--long {
  @include h3($visually-align: true);

  @include mq($bp-desktop) {
    @include h4;
  }
}

.hbs-card-topper__cta {
  margin-top: auto;
  padding-top: $spacing-md;

  .hbs-cta-link {
    max-width: 100%;
  }
}
